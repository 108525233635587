/* Properties */

::selection {
  background: #ddd;
}

/* HTML */

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: white;
  text-rendering: optimizeLegibility;
}

#root {
  height: 100%;
}

/* Body */

body {
  background: white;
  color: #444444;
  font-family: "Poppins", sans-serif;
  /* override emotion's default of 16px for better a11y support */
  font-size: inherit !important;
  height: 100vh;
  width: 100vw;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: "Poppins", sans-serif;
  letter-spacing: unset !important;
}
:-ms-input-placeholder {
  /* IE 10-11 */
  font-family: "Poppins", sans-serif;
  letter-spacing: unset !important;
}
:-moz-placeholder {
  /* Firefox <= 18 */
  font-family: "Poppins", sans-serif;
  letter-spacing: unset !important;
}
::-moz-placeholder {
  /* Firefox > 18 */
  font-family: "Poppins", sans-serif;
  letter-spacing: unset !important;
}
::placeholder {
  /* All others */
  font-family: "Poppins", sans-serif;
  letter-spacing: unset !important;
}

/* Markup */

* {
  box-sizing: border-box;
}

a {
  color: #fb5755;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #e2504d;
}

*:focus {
  outline: #e2504d auto 1px;
  outline-offset: 0.375rem;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}

button {
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  line-height: 1.5rem;
  padding: 0;
}

button:hover,
button:focus {
  color: #e2504d;
}

hr {
  border: 0;
  border-top: 1px solid #cbcbcb;
  height: 0;
  margin-bottom: calc(1.5rem - 1px);
}

iframe {
  border: 0;
}

input,
select {
  -webkit-appearance: none;
}

ol,
ul {
  margin: 0;
  padding-left: 1.25rem;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.3em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.8em;
}

p {
  font-size: 1em;
  margin: 0 0 1.5rem;
}

/* Media */

img,
svg {
  display: block;
}

img {
  max-width: 100%;
}

/* Screen Reader Text */

.srt {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/* Wide screens only */

.wideOnly {
  display: none;
}

@media (min-width: 50em) {
  .wideOnly {
    display: block;
  }
}

@media (min-width: 50em) {
  .narrowOnly {
    display: none;
  }
}
