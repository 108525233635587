/* Input */

.input {
  border: 0;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px lightgray;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5rem;
  letter-spacing: 0.25px;
  padding: 0.75rem 1.25rem;
  transition: 250ms box-shadow cubic-bezier(0.33, 1, 0.68, 1);
  width: 100%;
}
.input:focus {
  box-shadow: inset 0 0 0 1.5px #FC7A78;
  outline: none;
}
.input[type="password"] {
  letter-spacing: 3px;
}
.input::placeholder {
  color: #c9c9c9;
}
.input:disabled {
  background: #eee;
  color: #999;
}

/* Modifiers */

.input--hasError {
  box-shadow: inset 0 0 0 1.5px #FBC756;
}

/* Submodules */

.input-lock svg {
  color: #868686;
  font-size: 1.5rem;
  margin-right: 0.25rem;
  margin-top: .75rem;
  position: absolute;
  right: 0.625rem;
  top: 0;
}

.input:focus + .input-lock svg {
  color: #FB5755;
}
