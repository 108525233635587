.appLayout {
  /* Temporary - delete before merging! */
  background-color: white;

  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "body";
}

.appLayout--authenticated {
  /* Authenticated Grid */
  /* Desktop */
  grid-template-columns: min-content [sideBar] auto [body];
  grid-template-rows: auto;
  grid-template-areas: "sideBar body";
}

.appLayout--public {
  /* Public Grid */
  grid-template-columns: 1fr;
  grid-template-rows: min-content [topbar] auto [body];
  grid-template-areas: "topbar" "body";
}

@media (max-width: 50rem) {
  .appLayout--authenticated {
    grid-template-areas: "sideBar" "body";
    grid-template-columns: 1fr;
    grid-template-rows: min-content [sideBar] auto [body];
  }
}

.topbar {
  grid-area: topbar;
}

.sideBar {
  grid-area: sideBar;
  height: 100%;
}

.body {
  grid-area: body;
  height: 100%;
  padding: 2rem;
}
